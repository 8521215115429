/**
 * 全站http配置
 *
 * axios参数说明
 * isSerialize是否开启form表单提交
 * isToken是否需要token
 */
import axios from 'axios';
import { Notify } from "vant";
import router from '@/router/index';
import { baseURL } from '@/config/net.js'
const instance = axios.create({
    baseURL: process.env.NODE_ENV === 'development'
        ? '/api'
        : baseURL,
    timeout: 6000,
    validateStatus: function (status) {
        return status >= 200 && status <= 500;
    }

})

// //默认超时时间
// axios.defaults.timeout = 6000;
// //返回其他状态码
// axios.defaults.validateStatus = function (status) {
//     return status >= 200 && status <= 500;
// };
//跨域请求，允许保存cookie
// axios.defaults.withCredentials = true;

//http request拦截
instance.interceptors.request.use(config => {
    var token = localStorage.getItem('token')

    // const meta = (config.meta || {});
    // const isToken = meta.isToken === false;
    if (token) config.headers['Authorization'] = `Bearer ${token}`
    config.headers['Content-Type'] =
        'application/json;charset=UTF-8'
    // console.log(1)
    return config
}, error => {
    return Promise.reject(error)
});
//http response 拦截
instance.interceptors.response.use(res => {


    //获取状态码
    const status = Number(res.data.code) || res.status;
    console.log(res)
    const message = res.data.msg || '未知错误';
    // console.log(status, res)
    //如果是401则跳转到登录页面
    // if (status === 401) {
    //     localStorage.removeItem('token')
    //     router.push({ path: '/login' });
    // }
    // // 如果请求为非200否者默认统一处理
    // if (status !== 200&&status !== 201) {
    //     Message({
    //         message: message,
    //         type: 'error'
    //     });
    //     if (status == 999) {   //特殊情况，页面自行判断
    //         return res.data;
    //     }
    //     return Promise.reject(new Error(message))
    // }
    // return res.data;


    switch (status) {
        case 201:
            return res.data
        case 200:
            // 业务层级错误处理，以下是假定restful有一套统一输出格式(指不管成功与否都有相应的数据格式)情况下进行处理
            // 例如响应内容：
            // 错误内容：{ code: 1, msg: '非法参数' }
            // 正确内容：{ code: 200, data: {  }, msg: '操作正常' }
            // return data
            return res.data
        case 204:
            return res.data
        case 401:
            localStorage.clear();
            router.push({ path: '/app/login' });
            break
        // case 402:
        //   return await tryRefreshToken(config)
        // case 403:
        //   router.push({ path: '/403' }).then(() => { })
        //   break
        // default:
        //     // Message({
        //     //     message: message,
        //     //     type: 'error'
        //     // });
        //     Notify({
        //         message: message,
        //         type: 'danger'
        //     });
        //     return Promise.reject(new Error(message))

    }
    // 异常处理
    // 若data.msg存在，覆盖默认提醒消息
    Notify({
        message: message,
        type: 'danger'
    });

    return Promise.reject(res.data)

}, error => {
    return Promise.reject(new Error(error));
});

export default instance;
