<template>
  <div style="width: 100%; height: 100%">
    <div v-if="isLoading" class="myloading">
      <van-loading type="spinner" />
    </div>
    <div v-else class="box">
      <div class="title">阳春数字精酿智慧运营平台</div>
      <div class="content">
        <div>
          <van-form ref="vanform" label-width="3em">
            <div class="avatar" v-if="form.head">
              <van-image
                round
                width="10rem"
                height="10rem"
                :src="服务地址 + form.head"
              />
            </div>

            <van-field
              disabled
              v-model="form.username"
              label="用户名"
            ></van-field>

            <van-field
              disabled
              v-model="form.department"
              label="部门"
            ></van-field>
          </van-form>
          <div class="btn-warrper">
            <van-button round block type="info" @click="loginOut"
              >退出登录</van-button
            >
          </div>
        </div>
      </div>
    </div>
    <van-dialog
      v-model="outShow"
      title="退出登录"
      show-cancel-button
      @confirm="onDelConfirm"
      @cancel="onDelCancel"
    >
      是否要进行退出登录操作？
    </van-dialog>
  </div>
</template>

<script>
import { baseURL } from "@/config/net.js";
import { Notify } from "vant";
import { logout } from "@/api/login";
export default {
  name: "my",
  data() {
    return {
      服务地址: "",
      form: {},
      outShow: false,
      isLoading: false,
    };
  },

  computed: {},
  watch: {},
  mounted() {
    this.服务地址 = baseURL;
    this.form = JSON.parse(localStorage.getItem("userinfo"))
      ? JSON.parse(localStorage.getItem("userinfo"))
      : {};
  },
  methods: {
    loginOut() {
      this.outShow = true;
    },
    onDelConfirm() {
      this.isLoading = true;
      logout()
        .then(() => {
          Notify({ type: "success", message: "退出登录成功" });
          localStorage.clear();
          this.outShow = false;
          // window.location.href = window.location.origin + '/' + window.location.hash
          window.location.search = ''
          console.log(window.location)
          // this.$router.push("/app/login");
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    onDelCancel() {
      this.outShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  .title {
    width: 100%;
    height: 2rem;
    flex-shrink: 0;
    background-color: #fa6401;
    color: #fff;
    font-size: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content {
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    // background-color: #f3ccb2;
  }
  .content > div {
    width: 100%;
    height: 100%;
    padding: 0.9375rem;
  }
}
.avatar {
  width: 100%;
  display: flex;
  justify-content: center;
}
.btn-warrper {
  margin-top: 1.25rem;
}
</style>
