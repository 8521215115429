import request from '@/utils/request';

export const login = (data) => {
    return request({
        url: '/login',
        method: 'post',
        data: data
    })
}


export const userInfo = (data) => {
    return request({
        url: '/userInfo2',
        method: 'post',
        data: data
    })
}

export const logout = (data) => {
    return request({
        url: '/logout',
        method: 'post',
        data: data
    })
}
export const codetotoken = (data) => {
    return request({
        url: '/codetotoken',
        method: 'post',
        data: data
    })
}

